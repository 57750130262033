<nav>
  <div class="logo">
    SOUGILE</div>
  <input type="checkbox" id="click">
  <label for="click" class="menu-btn" style="margin-left: auto;">
    <i class="fas fa-bars"></i>
  </label>
  <ul style="line-height: 0 !important;">
    <label for="click" class="menu-btn">
      <i class="fas fa-minus"></i>
    </label>
    <li><a href="#home">Inicio</a></li>
    <li><a href="#our_services">Nuestros servicios</a></li>
    <li><a href="#clients">Testimonios</a></li>
    <li><a class="active" href="#contacts">Contactanos</a></li>
  </ul>
</nav>
<div class="whatsapp">
  <a href="https://api.whatsapp.com/send?phone=573103241776" class="float" target="_blank">
    <i class="fa fa-whatsapp my-float" style="margin-top: 15px;"></i>
  </a>
</div>
<div class="content" id="home">
  <img src="../assets/images/img_dos.png" alt="portatil" class="portatil">
  <p>Le pronunciamos la
    <br>
    Ultima Tecnologia
  </p>
  <div class="section">
    <button>Obtenga una consulta gratuita</button>
    <h6>La transformación digital es la adopción de digital
      <br>
      Tecnología para transformar servicios o negocios
    </h6>
  </div>
</div>

<div class="services">
  <img src="../assets/images/img_uno.jpg" alt="">
  <div class="type_services">
    <p>Por que deberías considerar <br> Utilizando nuestros servicios?</p>
    <ul>
      <li>
        <mat-icon>task_alt</mat-icon>
        <h1>Desarrollamos paginas web</h1>
      </li>
      <li>
        <mat-icon>task_alt</mat-icon>
        <h1>Desarollamos aplicativos moviles</h1>
      </li>
      <li>
        <mat-icon>task_alt</mat-icon>
        <h1>Automatizamos procesos complejos</h1>
      </li>
      <li>
        <mat-icon>task_alt</mat-icon>
        <h1>Disposición completa de asesoria y atencion al cliente</h1>
      </li>
    </ul>
    <a href="#contacts">
      <button class="btn_contact">Contactanos</button>
    </a>
  </div>
</div>
<div class="our_services" id="our_services">
  <p>Nuestros servicios</p>
  <h2>Transformacion digital</h2>
  <div class="services_intern">
    <div class="services_content">
      <mat-icon class="icons">face</mat-icon>
      <h3>Chat Bot</h3>
      <label class="label_one">Integración de chat bot, a tu sistema</label><br>
      <li>
        <a (click)="openDialog('chatBot')">Más información</a>
        <mat-icon>arrow_forward</mat-icon>
      </li>
    </div>
    <div class="services_content">
      <mat-icon class="icons">public</mat-icon>
      <h3>Portal Web</h3>
      <label class="label_one">Podemos desarrollar portal web</label><br>
      <li>
        <a (click)="openDialog('portalWeb')">Más información</a>
        <mat-icon>arrow_forward</mat-icon>
      </li>
    </div>
    <div class="services_content">
      <mat-icon class="icons">apps</mat-icon>
      <h3>Aplicaciones de Mensajería (WhatsApp)</h3>
      <label class="label_one">Desarrollamos tu aplicaciones</label><br>
      <li>
        <a (click)="openDialog('aplicacionesMensajeria')">Más información</a>
        <mat-icon>arrow_forward</mat-icon>
      </li>
    </div>
  </div>
  <div class="services_intern">
    <div class="services_content">
      <mat-icon class="icons">device_hub</mat-icon>
      <h3>Chat Asesores</h3>
      <label class="label_one">Desarrollamos procesos complejos para garantizar la mayor comodidad y facilidad en tu
        sistema</label><br>
      <li>
        <a (click)="openDialog('chatAsesores')">Más información</a>
        <mat-icon>arrow_forward</mat-icon>
      </li>
    </div>
    <div class="services_content">
      <mat-icon class="icons">fact_check</mat-icon>
      <h3>Agenda de Atención - Asesores</h3>
      <label class="label_one">Contamos con prefesionales aptos para tus necesidades y garantizamos estar siempre a tu
        disposicion</label><br>
      <li>
        <a (click)="openDialog('agendaAtencion')">Más información</a>
        <mat-icon>arrow_forward</mat-icon>
      </li>
    </div>
    <div class="services_content">
      <mat-icon class="icons">apk_document</mat-icon>
      <h3>Sistemas de Consulta</h3>
      <label class="label_one">Contamos con prefesionales aptos para tus necesidades y garantizamos estar siempre a tu
        disposicion</label><br>
      <li>
        <a (click)="openDialog('sistemasConsulta')">Más información</a>
        <mat-icon>arrow_forward</mat-icon>
      </li>
    </div>
  </div>
</div>
<div class="clients" id="clients">
  <h1>Nuestro clientes satisfechos</h1>
  <p-carousel [value]="msns" [autoplayInterval]="3000" [numVisible]="3" [responsiveOptions]="responsiveOptions">
    <ng-template let-msn pTemplate="item">
      <mat-card class="example-card">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image">
            <span class="material-icons" style="width: 50%;">
              account_circle
            </span>
          </div>
          <mat-card-title>{{msn.nombre}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            {{msn.mensaje}}
          </p>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </p-carousel>
</div>
<div class="our_clients" style="margin-bottom: 80px;">
  <h1>Nuestros clientes</h1>
  <p-carousel [value]="clientes" [autoplayInterval]="3000" [numVisible]="3" [responsiveOptions]="responsiveOptions">
    <ng-template let-cliente pTemplate="item">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{cliente.descripcion}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="cont_deslumbra">
            <img src="{{cliente.img}}" alt="">
            <a href="{{cliente.url}}" class="visitar">Visitar</a>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </p-carousel>
</div>

<div class="contacts" id="contacts">
  <h1>CONTÁCTANOS</h1>
  <div class="social_media">
    <div class="ubicacion">
      <h2>Contactanos</h2>
      <h3>Correo electronico:</h3>
      <p>jhonatanc@sougile.com</p>
      <h3>Telefono:</h3>
      <p>+57 310 3241776</p>
    </div>
    <div class="us_contact">
      <input type="text" placeholder="Correo electronico">
      <input type="text" placeholder="Numero de telefono">
      <input type="text" placeholder="Mensaje">
      <button>Contactanos</button>
    </div>
  </div>
  <div class="copy">
    <p>Copyright @2022 Sougile</p>
  </div>
</div>