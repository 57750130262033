<mat-dialog-content class="mat-typography">
    <div *ngIf="data == 'chatBot'">
        <h1>Chat Bots</h1>
        <div class="chatBots">
            <p>En el cambiante mundo las tecnologías se han convertido en un pilar fundamental en la sociedad. Este
                cambiante mundo en donde las preguntas, los trámites, las consultas, cotizaciones y muchos más temas
                hacen parte del día a día de la comunidad y que están siendo reemplazados por tecnologías como son los
                ChatBots. En este punto es donde nos preguntamos ¿Qué es un ChatBot?. En el mercado existen muchas
                definiciones para este concepto, sin embargo, desde el mundo de Sougile y su estrategia de
                transformación digital para las compañías lo definimos como una herramienta tecnológica que agiliza los
                trámites en donde las respuestas a preguntas de la organización se conocen previamente. Ejemplos comunes
                de ChatBots los podemos encontrar en contextos como cotizaciones de servicios (conocemos los valores de
                los productos como costos de alojamiento, costos de envíos, etc.), preguntas información (Se manejan en
                contextos propios de la organización como direcciones, horarios y teléfonos), cálculos de alguna índole
                (Calculadoras de nóminas, cálculos de tarjetas de crédito,..), el mundo de posibilidades es casi
                infinito y para los casos en donde no podamos tener una respuesta se puede hacer un mix con una persona
                física que atienda nuestra solicitud.</p>
            <div class="imagenes">
                <img src="../../assets/images/chatBotImage.jpg" alt="chatBot">
                <a href="http://www.freepik.com">Designed by stories / Freepik</a>
            </div>
        </div>
    </div>
    <div *ngIf="data == 'portalWeb'">
        <h1>Portal Web</h1>
        <div class="chatBots">
            <p>Este tipo de soluciones se aplican específicamente para cuando nuestro cliente necesita a través del
                portal de la compañía dar respuestas rápidas al cliente. En otras palabras, es un servicio que se
                embebe en la página web de nuestra empresa y así poder brindar los servicios.
                <br><br>
                <b>● Automático.</b> Se tiene un marco de todas las preguntas y unas respuestas posibles o calculadas
                para
                nuestros usuarios. Este sistema puede mantener un base de respuestas y también consultar sistemas
                internos o externos de la compañía como puede ser bonos, pensiones, cálculos. No tiene interacción
                con un asesor.
                <br><br>
                <b>● Asesor fuera de línea.</b> Corresponde al mismo servicio de automático, pero llegado el caso en
                donde
                el usuario no logre encontrar la información, el usuario puede solicitar que un asesor se comunique
                con el usuario.
                <br><br>
                <b>● Asesor en línea.</b> Corresponde al mismo servicio de automático, pero llegado el caso en donde el
                usuario no logre encontrar la información, el usuario se conectará con un asesor experto en línea
                para atender la solicitud.
            </p>
            <div class="imagenes">
                <img src="../../assets/images/image003.jpg" alt="chatBot">
            </div>
        </div>
    </div>
    <div *ngIf="data == 'aplicacionesMensajeria'">

        <h1>Aplicaciones de Mensajería (WhatsApp)</h1>
        <div class="chatBots">
            <p>Este tipo de servicio se brinda como un intermediario que a través de un número de teléfono
                previamente configurado nuestros clientes pueden realizar las respectivas consultas.
                <br><br>
                <b>● Automático.</b> Se tiene un marco de todas las preguntas y unas respuestas posibles o
                calculadas para nuestros usuarios. Este sistema puede mantener un base de respuestas y también
                consultar sistemas internos o externos de la compañía como puede ser bonos, pensiones, cálculos. No
                tiene interacción con un asesor.
                <br><br>
                <b>● Asesor fuera de línea.</b> Corresponde al mismo servicio de automático, pero llegado el caso en
                donde el usuario no logre encontrar la información, el usuario puede solicitar que un asesor se
                comunique con el usuario.
                <br><br>
                <b>● Asesor en línea.</b> Corresponde al mismo servicio de automático, pero llegado el caso en donde
                el usuario no logre encontrar la información, el usuario se conectará con un asesor experto en línea
                para atender la solicitud.
            </p>
            <div class="imagenes">
                <img src="../../assets/images/image005.jpg" alt="chatBot">
            </div>
        </div>
    </div>
    <div *ngIf="data == 'chatAsesores'">

        <h1>Chat Asesores</h1>
        <div class="chatBots">
            <p>Otro de los mecanismos que influyen en el cambio de pensamiento organizacional y transformación
                digital de común uso es conocido como el chat con asesores directamente. Esta interacción permite
                que los usuarios generen un chat en tiempo real con expertos en cada uno de los temas y así dar una
                solución a los usuarios. Un centro de experiencia está conectado en back para atender la solicitud.
                Esta solución viene dada por dos medios:
                <br><br>
                El proceso de uso es muy parecido al de los chatbots con la diferencia de que no están precargadas
                las respuestas y/o opciones presentadas al cliente, en su lugar un asesor experto toma la
                conversación inmediatamente.
                <br><br>
                Sougile cuenta con una plataforma en constante evolución para mejorar los servicios tanto del lado
                del cliente como sistemas de seguimiento y auditoría de la información.
            </p>
            <div class="imagenes">
                <img src="../../assets/images/image008.gif" alt="chatBot">
            </div>
        </div>
    </div>
    <div *ngIf="data == 'agendaAtencion'">

        <h1>Agenda de Atención - Asesores</h1>
        <div class="chatBots">
            <p>Un sistema que proporciona una gran utilidad para nuestros clientes es el sistema de agendamiento de
                llamadas. Este sistema permite que los usuarios definan la hora específica a la que desean que el asesor
                lo llame para atender la solicitud. Este canal de transformación digital permite descongestionar los
                medios tradicionales de comunicación como son las llamadas a IVR y así realizar un mejor seguimiento a
                los usuarios optimizando los tiempos de atención.</p>
            <div class="imagenes">
                <img src="../../assets/images/image010.jpg" alt="chatBot">
            </div>
        </div>
    </div>
    <div *ngIf="data == 'sistemasConsulta'">

        <h1>Sistemas de Consulta</h1>
        <div class="chatBots">
            <p>Nuestros servicios no solo están centralizados en los sistemas transversales, también se ha logrado
                desarrollar transformaciones necesarias para atender mejor al cliente en general y que así se pueda
                llegar a brindar una mejor atención.</p>
            <div class="imagenes">
                <img src="../../assets/images/image012.jpg" alt="chatBot">
            </div>
        </div>
    </div>
</mat-dialog-content>