import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InformacionComponent } from './informacion/informacion.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SOUGILE';

  msns: any[];
  responsiveOptions: any[];
  nombre: string[];

  clientes: any[] = [];

  constructor(public dialog: MatDialog) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    this.msns = [];
    let msn = {
      mensaje: 'La pagina web que me han realizado quedo excelente espero contar con sus servicios más adelantes',
      nombre: 'Estiven Ferreira',
    };
    this.msns.push(msn);
    msn = {
      mensaje: 'Me encanto su servicío al cliente, siempre estan atentos a su lineas de contacto  y son cumplidos con los tiempos establecidos',
      nombre: 'Alisson Garcia',
    };
    this.msns.push(msn);
    msn = {
      mensaje: 'La app que lograron hacer mapara mi movil, me encato espero contar con ellos para una proximas vez',
      nombre: 'Jose pinzon',
    };
    this.msns.push(msn);
    msn = {
      mensaje: 'El lograron pasar todas las necesidades de mi empresa a la pagina',
      nombre: 'Ana Maria Gomez',
    };
    this.msns.push(msn);
    msn = {
      mensaje: 'El sistema que desarrollaron me fasino cumplen con el contrato establecido',
      nombre: 'Miriam Rodriguez',
    };
    this.msns.push(msn);
    msn = {
      mensaje: 'Garantizan un excelente servicio, y ademas cumplen que lo prometido',
      nombre: 'Jaime Garcia',
    };
    this.msns.push(msn);
    msn = {
      mensaje: 'Tenia inconvenientes con la app, pero pero gracias a su servicio se logro todo muy facilmente, lo recomiendo.',
      nombre: 'Maria camila Ruis',
    };
    this.msns.push(msn);

    this.clientes = [{
      descripcion: 'Para publicar Existen portales, para comprar, vender o arrendar deslumbra',
      img: '../assets/images/logo2.png',
      url: 'https://deslumbra.com',
    }, {
      descripcion: 'Para publicar Existen portales, para comprar, vender o arrendar deslumbra',
      img: 'https://coomultransvilla.com/gallery_gen/1ae9223e2ca971329c401e467af23d65_220x120.24844720497.jpg',
      url: 'https://coomultransvilla.com/Rutas/',
    }, {
      descripcion: 'Para publicar Existen portales, para comprar, vender o arrendar deslumbra',
      img: 'https://www.cofrem.com.co/assets/images/logo_cofrem.svg',
      url: 'https://www.cofrem.com.co/',
    }, {
      descripcion: 'Para publicar Existen portales, para comprar, vender o arrendar deslumbra',
      img: '../assets/images/logoCajamag.png',
      url: 'https://www.cajamag.com.co/',
    }, {
      descripcion: 'Para publicar Existen portales, para comprar, vender o arrendar deslumbra',
      img: '../assets/images/logoCer.png',
      url: 'https://cerexperiencia.com/#/',
    },
    {
      descripcion: 'Para publicar Existen portales, para comprar, vender o arrendar deslumbra',
      img: 'https://asopagos.com/templates/as002063/images/logo.png',
      url: 'https://asopagos.com/index.php',
    }
    ]
  }
  openDialog(value: string): void {
    this.dialog.open(InformacionComponent, {
      data: value
    });
  }
}
